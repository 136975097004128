import sticker from "@/assets/images/sticker.gif";
import { useModal } from "@/components/modal/hooks";
import { SectionHeader } from "@/sections/components/section-header";
import { SectionProps } from "@/types";

import "./styles.scss";

export const Customisation: React.FC<SectionProps> = ({ swiper }) => {
  const { setIsModalOpen } = useModal();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  return (
    <>
      <SectionHeader
        title="Customisation"
        prevTitle={{
          title: "Models",
          onClick: () => swiper?.slidePrev(),
        }}
        verticalAlign="bottom"
      />
      <div className="section__content">
        <div className="section__content-wrapper">
          <p className="section__text" data-swiper-parallax="-200">
            Oduwan provides with a total liberty in sizing, geometry, and features you want to
            experience. <span className="br" />
            Triple triangle. Lo-pro. Fishbones. Whatever your dream bike is, reflect yourself with
            no limitations, and Oduwan's unparalleled craftmanship will turn your aspirations into
            reality. <span className="br" />
            Not sure where to start? Contact us and Oduwan will consult you on the endless
            customisation possibilities.
          </p>
          <img className="section__image" src={sticker} alt="sticker" />
        </div>
        <div className="gallery-entry">
          <div className="gallery-entry__background">
            {new Array(16).fill(null).map((_, index) => {
              const src = `sections/bikes/customisation/gallery-entry/gallery-entry-${index + 1}`;

              return (
                <img
                  src={require(`@/assets/images/${src}.jpg`)}
                  className="gallery-entry__image"
                  alt="bike"
                  key={src}
                />
              );
            })}
          </div>
          <div className="gallery-entry__caption">
            <p className="section__text" data-swiper-parallax="-200">
              Explore Oduwan creations built for the most demanding riders across the world.{" "}
              <span className="br" />
              Your project can be the next one to showcase here.
            </p>
            <button type="button" className="button button-outline" onClick={handleClick}>
              Go to Gallery
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
