import { Divider } from "@/sections/components/divider";
import { SectionHeader } from "@/sections/components/section-header";
import { SectionProps } from "@/types";

import { Specs } from "./components/specs";

import "./styles.scss";

export const Models: React.FC<SectionProps> = ({ swiper }) => {
  return (
    <>
      <SectionHeader
        title="Models"
        verticalAlign="bottom"
        nextTitle={{
          title: "Customisation",
          onClick: () => swiper?.slideNext(),
        }}
      />
      <div className="section__content">
        <div className="section__content-wrapper">
          <p className="section__text" data-swiper-parallax="-200">
            Oduwan is truly bespoke — each bike is made to order. This is why instead of models we
            propose three base concepts with standards carefully chosen for the best compatibility
            with high-end components.
            <span className="br" /> You are free to customise any aspect of your Oduwan — or even
            create something completely new. Yet if there is space for improvement in your vision,
            Oduwan will use it. Because it's our expertise that turns your ideas into a remarkable
            work of art.
          </p>
        </div>
        <Divider direction="vertical" />
        <Specs />
      </div>
    </>
  );
};
