import { Divider } from "../divider";

import "./styles.scss";

type TitleOptions = {
  title: string;
  onClick: () => void;
};

type ComponentProps = {
  title: string;
  textAlign?: "center" | "left" | "right";
  verticalAlign?: "top" | "bottom";
  parallax?: number;
  prevTitle?: TitleOptions;
  nextTitle?: TitleOptions;
};

export const SectionHeader = ({
  title,
  textAlign = "center",
  verticalAlign = "top",
  parallax = -200,
  prevTitle,
  nextTitle,
}: ComponentProps) => {
  const renderTitle = ({
    title,
    onClick,
    className,
  }: {
    title: string;
    onClick?: () => void;
    className?: string;
  }) => {
    return (
      <h2
        className={`section__header-title section__header-title--${textAlign} ${
          className ? className : ""
        }`}
        onClick={onClick}
        data-swiper-parallax={parallax}
      >
        {title}
      </h2>
    );
  };

  return (
    <div
      className={`section__header${verticalAlign === "bottom" ? " section__header--bottom" : ""}`}
    >
      <Divider direction="horizontal" className={verticalAlign === "top" ? " mt" : ""} />
      <div
        className={`section__header-title__container${
          prevTitle || nextTitle ? " section__header-title__container--flex" : ""
        }`}
      >
        {prevTitle && renderTitle({ ...prevTitle, className: "section__header-title--prev" })}
        {renderTitle({ title })}
        {nextTitle && renderTitle({ ...nextTitle, className: "section__header-title--next" })}
      </div>

      <Divider direction="horizontal" className={verticalAlign === "bottom" ? " mb" : ""} />
    </div>
  );
};
