import { Divider } from "@/sections/components/divider";

import { Spirit, Story } from "./subsections";

import "./styles.scss";

export const Oduwan = () => {
  return (
    <div className="row">
      <Story />
      <Divider direction="vertical" />
      <Spirit />
    </div>
  );
};
