import { createHashRouter } from "react-router-dom";

import { MainPage } from "@/pages";
import { BASE_PATH } from "@/pages/constants";

export const router = createHashRouter([
  {
    path: `${BASE_PATH}/:section?/:projectId?`,
    element: <MainPage />,
  },
]);
