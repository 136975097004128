import { useEffect, useState } from "react";

import { preloadImages } from "@/pages/utils";

export const usePreloadImages = (imagesSrc: string[]) => {
  const [images, setImages] = useState<HTMLImageElement[]>([]);

  useEffect(() => {
    const loadedImages = preloadImages(imagesSrc);
    setImages(loadedImages);
  }, [imagesSrc]);

  return { images };
};
