import React, { memo } from "react";

import "./styles.scss";

type ComponentProps = {
  offset: number;
  width: number;
  placement?: "top" | "bottom";
};

export const ProgressBar = memo(({ offset, width, placement = "bottom" }: ComponentProps) => (
  <div
    className={`progress progress--${placement}`}
    style={{ left: `${offset}px`, width: `${width}px` }}
  ></div>
));
