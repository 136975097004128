import { Link } from "react-router-dom";

import logo from "@/assets/images/logo.gif";
import logoText from "@/assets/images/logo-text.png";
import { BASE_PATH } from "@/pages/constants";
import { Divider } from "@/sections/components/divider";

import "./styles.scss";

export const Hero = () => {
  return (
    <div className="section__content row">
      <Divider direction="vertical" />
      <div className="hero__body" role="banner">
        <div className="hero__image-container">
          <img className="hero__image" src={logo} alt="oduwan" data-swiper-parallax="-500" />
          <img
            className="hero__image"
            src={logoText}
            alt="built for me"
            data-swiper-parallax="-600"
          />
        </div>
        <div className="hero__button-container">
          <Link to={`${BASE_PATH}/contact`} className="button hero__button">
            Order now
          </Link>
          <Link to={`${BASE_PATH}/oduwan`} className="button hero__button">
            Know more
          </Link>
        </div>
      </div>
      <Divider direction="vertical" />
    </div>
  );
};
