import { useState } from "react";

import { ReactComponent as CopiedIcon } from "@/assets/images/icons/check-mark-icon.svg";
import { ReactComponent as CopyToClipboardIcon } from "@/assets/images/icons/copy-to-clipboard-icon.svg";
import { ContactForm } from "@/components/forms/contact-form";
import { Divider } from "@/sections/components/divider";
import { SectionHeader } from "@/sections/components/section-header";

import "./styles.scss";

const link = "contact.oduwan@gmail.com";

export const Contact = () => {
  const [isLinkCopied, setLinkCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  return (
    <>
      <SectionHeader title="Discuss your Oduwan" verticalAlign="top" />
      <div className="section__content">
        <Divider direction="vertical" />
        <div className="section-wrapper">
          <h3 className="section__text">
            Got any questions left? Interested in buying Oduwan?
            <span className="br" /> Send us an email on {link},
            <span onClick={handleCopyLink}>
              {isLinkCopied ? <CopiedIcon /> : <CopyToClipboardIcon />}
            </span>
            hit Instagram DMs at{" "}
            <a
              className="section__link"
              href="https://www.instagram.com/oduwan.bikes/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @oduwan.bikes
            </a>{" "}
            or leave a note below -- and we will contact you soon.
          </h3>
          <ContactForm />
        </div>
        <Divider direction="vertical" />
      </div>
    </>
  );
};
