import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Button, Col, ConfigProvider, Form, Input, notification, Row } from "antd";
import { LegacyRef, useRef } from "react";

import { strings, validationRules } from "./strings";

const FORM_ACCESS_KEY = process.env.REACT_APP_FORM_ACCESS_KEY;
const CAPTCHA_ACCESS_KEY = process.env.REACT_APP_CAPTCHA_ACCESS_KEY;

const themeConfig = {
  token: {
    colorPrimary: "#1a1819",
    colorBgContainer: "#f8f9fac2",
  },
  components: {
    Message: {
      contentBg: "#dbdbdb",
    },
  },
};

type FormValues = { name: string; email: string; message: string; h_captcha_response: string };

export const ContactForm = () => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const captchaRef: LegacyRef<HCaptcha> = useRef(null);

  const showMessage = (message: string) => {
    api.open({
      message: message,
      placement: "bottomLeft",
      duration: 4,
      showProgress: true,
    });
  };

  const onSubmit = async (values: FormValues) => {
    const data = await captchaRef.current?.execute({ async: true });

    if (data?.response && FORM_ACCESS_KEY) {
      const { name, email, message } = values;

      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("message", message);
      formData.append("h-captcha-response", data?.response);
      formData.append("access_key", FORM_ACCESS_KEY);

      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (data.success) {
          showMessage(strings.formSubmitSuccess);
          captchaRef.current?.resetCaptcha();
          form.resetFields();
        } else {
          console.log("Error", data);
          showMessage(strings.captchaError);
        }
      } catch (error) {
        console.error("Error submitting the form", error);
        showMessage(strings.formSubmitError);
      }
    }
  };

  return (
    <ConfigProvider theme={themeConfig}>
      {contextHolder}
      <Form
        form={form}
        name="contact_form"
        className="contact__form"
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row gutter={[24, 24]} justify="center" wrap className="contact__form__row">
          <Col xs={24} sm={12}>
            <Form.Item name="name" rules={validationRules.name} className="contact__form-item">
              <Input placeholder="Name" className="contact__form-item__input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="email" rules={validationRules.email} className="contact__form-item">
              <Input placeholder="Email" type="email" className="contact__form-item__input" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="contact__form__row">
          <Col span={24}>
            <Form.Item
              name="message"
              rules={validationRules.message}
              className="contact__form-item"
            >
              <Input.TextArea
                placeholder="Message"
                rows={5}
                className="contact__form-item__input"
              />
            </Form.Item>
          </Col>
        </Row>
        {CAPTCHA_ACCESS_KEY && (
          <HCaptcha
            sitekey={CAPTCHA_ACCESS_KEY}
            onExpire={captchaRef.current?.resetCaptcha}
            theme="dark"
            size="invisible"
            ref={captchaRef}
          />
        )}
        <Row>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              className="button button-outline contact__form-button"
            >
              Send Message
            </Button>
          </Col>
        </Row>
      </Form>
    </ConfigProvider>
  );
};
