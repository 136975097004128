import { SwiperClass } from "swiper/react";

export type SectionProps = {
  swiper?: SwiperClass | null;
};

export type Section = {
  slideIndex: number;
  className: string;
  title: string;
  path: string;
  component: React.FC<SectionProps>;
  hidden?: boolean;
};

export type Project = { title: string; id: string };

export enum Theme {
  Light = "light",
  Dark = "dark",
}

export type ThemeConfig = {
  header: Theme;
  footer: Theme;
};
