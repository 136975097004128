import { ReactComponent as InstagramIcon } from "@/assets/images/icons/instagram-icon.svg";
import { Divider } from "@/sections/components/divider";
import { SectionHeader } from "@/sections/components/section-header";

import "./styles.scss";

export const Club = () => {
  return (
    <>
      <SectionHeader title="Oduwan Club" verticalAlign="top" />
      <div className="section-wrapper">
        <div className="section__content">
          <p className="section__text" data-swiper-parallax="-200">
            Oduwan was founded with support of friends. Soon, many of them were riding their own
            Oduwans. Rather than being yet another bike brand, Oduwan united cycling enthusiasts in
            a growing community: Oduwan Club.
            <span className="br" /> Oduwan Club promotes independence from stereotypes that surround
            cycling culture. Tied neither to brand-focused consumerism nor to anti-system protest,
            Oduwan Club encourages joy of riding bikes at its purest.
            <span className="br" /> To cycle with friends or meditate alone. To explore the city or
            have a bikepacking getaway. To race alleycats or enjoy a chill night ride. Whatever you
            are in the mood for, Oduwan Club is free of judgement and always nearby.
          </p>
          <a
            href="https://www.instagram.com/oduwan.bikes/"
            className="section__button button-outline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
            Join the club
          </a>
        </div>
        <Divider direction="vertical" />
        <div className="club__gallery">
          {Array.from(Array(7)).map((_, index) => (
            <img
              className="club__gallery-item"
              src={require(`../../assets/images/sections/club/club-${index + 1}.jpg`)}
              alt=""
              key={index}
            />
          ))}
        </div>
      </div>
    </>
  );
};
