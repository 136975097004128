import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper } from "swiper/types";

import { Theme } from "@/types";

import { ProgressBar } from "../progress-bar";
import { useProgressBar } from "../progress-bar/hooks";

import { NavbarMenu } from "./components/navbar-menu";

import "./styles.scss";

type ComponentProps = {
  swiper: Swiper | null;
  navbarItems: {
    slideIndex: number;
    title: string;
    path: string;
  }[];
  theme: Theme;
};

export const Header = ({ swiper, navbarItems, theme }: ComponentProps) => {
  const [activeLink, setActiveLink] = useState<HTMLAnchorElement | null | undefined>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([]);

  const { progressBarOffset, progressBarWidth } = useProgressBar(activeLink);

  const handleClick = (e: React.MouseEvent, slideIndex: number) => {
    e.preventDefault();
    swiper?.slideTo(slideIndex);
  };

  const updateLinkFocus = useCallback(() => {
    if (!swiper) return;

    const { activeIndex } = swiper;
    const link = linkRefs.current[activeIndex] ?? linkRefs.current[activeIndex - 1];
    setActiveLink(link);
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", updateLinkFocus);
    }
  }, [swiper, updateLinkFocus]);

  const handleMenuOpen = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsMenuOpen(e.target.checked);
  };

  useEffect(() => {
    updateLinkFocus();
  }, [updateLinkFocus]);

  return (
    <header className="header" data-theme={theme}>
      <nav id="navbar" className="navbar" role="navigation">
        <ProgressBar offset={progressBarOffset} width={progressBarWidth} />
        <input
          className="navbar__burger-menu-toggle navbar__burger-menu-toggle--hidden visually-hidden"
          id="toggleMenu"
          type="checkbox"
          checked={isMenuOpen}
          onChange={(e) => handleMenuOpen(e)}
        />
        <label className="navbar__burger-menu-toggle" htmlFor="toggleMenu">
          <div className="navbar__burger-menu-bar" />
          <div className="navbar__burger-menu-bar" />
          <div className="navbar__burger-menu-bar" />
        </label>
        <NavbarMenu
          navbarItems={navbarItems}
          linkRefs={linkRefs}
          handleClick={handleClick}
          activeItemTitle={activeLink?.dataset.title}
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
        />
      </nav>
    </header>
  );
};
