import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { A11y, Grid, Keyboard, Mousewheel, Navigation, Scrollbar, Thumbs } from "swiper/modules";
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from "swiper/react";

import { ReactComponent as CrossIcon } from "@/assets/images/icons/cross-icon.svg";
import { projects } from "@/pages/constants";
import { importAll } from "@/pages/utils";

import { Tabs } from "./components/tabs";
import { usePreloadImages } from "./hooks/use-preload-images";

import "./styles.scss";

export const Gallery = ({ closeGallery }: { closeGallery: () => void }) => {
  const navigate = useNavigate();
  const { projectId = "" } = useParams();

  const imagesSrc = useMemo(
    () =>
      importAll(
        require.context(
          "@/assets/images/sections/bikes/customisation/gallery/",
          true,
          /\.(png|jpe?g|svg)$/,
        ),
      ),
    [],
  );

  const { images } = usePreloadImages(imagesSrc);

  const project = projects.find(({ id }) => id === projectId) ?? projects[0];

  const projectImages = useMemo(
    () => images.filter((img) => img.src.includes(project.id)),
    [images, project.id],
  );

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  const sliderParams: SwiperProps = {
    className: "gallery__slider",
    keyboard: { enabled: true },
    modules: [Keyboard, Navigation, Thumbs, A11y],
    navigation: true,
    onSwiper: setSwiper,
    preventInteractionOnTransition: true,
    slidesPerView: "auto",
    speed: 300,
    thumbs: { swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null },
    spaceBetween: 10,
  };

  const thumbsParams: SwiperProps = {
    className: "gallery__slider-thumbs",
    direction: "horizontal",
    modules: [Navigation, Thumbs, Scrollbar, Mousewheel, Grid, A11y],
    mousewheel: true,
    onSwiper: setThumbsSwiper,
    scrollbar: { hide: false, draggable: false },
    slidesPerView: 3,
    slidesPerGroup: 3,
    grid: {
      rows: 2,
    },
    spaceBetween: 5,
    breakpoints: {
      500: {
        autoHeight: true,
        direction: "vertical",
        spaceBetween: 10,
        grid: undefined,
      },
    },
    watchSlidesProgress: true,
    lazyPreloadPrevNext: 1,
  };

  useEffect(() => {
    if (!projectId) {
      const { id } = project;
      navigate(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (swiper && projectId) {
      swiper.slideTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const renderSwiper = (swiperParams: SwiperProps) => {
    const { className } = swiperParams;

    return (
      <Swiper key={className} {...swiperParams}>
        {projectImages.map(({ src }) => (
          <SwiperSlide key={src}>
            <img srcSet={`${src} 1x, ${src} 2x`} className="gallery__image" alt={project.title} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <div className="gallery-wrapper">
      <div className="gallery__button gallery__button--back" onClick={closeGallery}>
        <CrossIcon />
      </div>
      <div className="gallery">{projectId && [sliderParams, thumbsParams].map(renderSwiper)}</div>
      <Tabs items={projects} project={project} />
    </div>
  );
};
