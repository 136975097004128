import spirit1 from "@/assets/images/sections/oduwan/spirit-1.jpg";
import spirit2 from "@/assets/images/sections/oduwan/spirit-2.jpg";
import { SectionHeader } from "@/sections/components/section-header";

import "./styles.scss";

export const Spirit = () => (
  <section className="section spirit">
    <SectionHeader title="Spirit" />
    <div className="section__content">
      <p className="section__text" data-swiper-parallax="-200">
        Inspired by Japanese masters like Tetsuro Harada or Yoshiaki Nagasawa who upheld the
        traditional craftsmanship in the age of outsourced mass manufacturing, Oduwan puts the
        values of quality and commitment first.
        <span className="br" />
        Instead of chasing profit or scale, we strive to keep the spirit of the mastery, spirit of
        the culture, and spirit of the community alive — and do it well.
      </p>
      <div className="row">
        <img className="section__image" src={spirit1} alt="" />
        <img className="section__image" src={spirit2} alt="" />
      </div>
    </div>
  </section>
);
