import "./styles.scss";

type ComponentProps = {
  direction: "vertical" | "horizontal";
  className?: string;
};

export const Divider = ({ direction, className }: ComponentProps) => {
  return <hr className={`divider divider--${direction}${className ?? ""}`} />;
};
