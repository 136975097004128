export const strings = {
  nameRequired: "Please input your name",
  emailRequired: "Please input your email",
  messageRequired: "Please input your message",
  captchaError: "Error submitting captcha, please reload page and try again.",
  formSubmitError: "Error submitting the form, please reload page and try again.",
  formSubmitSuccess: "Thank you! Oduwan will review your message.",
};

export const validationRules = {
  name: [{ required: true, message: strings.nameRequired }],
  email: [{ required: true, message: strings.emailRequired }],
  message: [{ required: true, message: strings.messageRequired }],
};
