import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { ProgressBar } from "@/components/progress-bar";
import { useProgressBar } from "@/components/progress-bar/hooks";
import { BASE_PATH } from "@/pages/constants";
import { Project } from "@/types";

type TabItem = Project;

type ComponentProps = {
  items: TabItem[];
  project: Project;
};

export const Tabs = ({ items, project }: ComponentProps) => {
  const linkRefs = useRef<Array<HTMLAnchorElement | null>>([]);
  const [activeLink, setActiveLink] = useState<HTMLAnchorElement | null | undefined>(null);
  const { progressBarWidth, progressBarOffset } = useProgressBar(activeLink);

  useEffect(() => {
    const link = linkRefs.current.find((linkRef) => linkRef && linkRef.dataset.id === project.id);
    link?.scrollIntoView({ behavior: "smooth" });
    setActiveLink(link ?? linkRefs.current[0]);
  }, [linkRefs, project]);

  return (
    <div className="tabs">
      <ProgressBar offset={progressBarOffset} width={progressBarWidth} placement="top" />
      {items.map(({ title, id }, index) => (
        <Link
          className="tabs__item"
          to={`${BASE_PATH}/customisation/${id}`}
          ref={(link) => (linkRefs.current[index] = link)}
          data-id={id}
          key={title}
        >
          {title}
        </Link>
      ))}
    </div>
  );
};
