import { ReactNode, useState } from "react";

import { ModalContext } from "./hooks/use-modal";

import "./styles.scss";

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
    </ModalContext.Provider>
  );
};

export const Modal = ({
  children,
  onClickOutside,
}: {
  children: ReactNode;
  onClickOutside: (e: React.MouseEvent) => void;
}) => (
  <div className="modal" onClick={onClickOutside}>
    {children}
  </div>
);
