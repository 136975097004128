import FontFaceObserver from "fontfaceobserver";
import { useEffect, useState } from "react";

export const usePageLoader = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const fontObserver = new FontFaceObserver("Hanson");
    let timer: NodeJS.Timeout | null = null;

    fontObserver.load().then(() => {
      timer = setTimeout(() => {
        setIsPageLoading(false);
      }, 1000);
    });

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return { isPageLoading };
};
