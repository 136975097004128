import preloaderImage from "@/assets/images/preloader.gif";

import "./styles.scss";

export const Preloader = () => {
  return (
    <div className="preloader">
      <img src={preloaderImage} alt="Loading..." className="preloader__image" />
    </div>
  );
};
