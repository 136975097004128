import { useEffect, useState } from "react";

export const useProgressBar = (activeLink?: HTMLAnchorElement | null) => {
  const [progressBarWidth, setProgressBarWidth] = useState<number>(0);
  const [progressBarOffset, setProgressBarOffset] = useState<number>(0);

  useEffect(() => {
    if (activeLink) {
      const updateSize = () => {
        setProgressBarOffset(activeLink.offsetLeft);
        setProgressBarWidth(activeLink.offsetWidth);
      };

      updateSize();
      window.addEventListener("resize", updateSize);
      return () => window.removeEventListener("resize", updateSize);
    }
  }, [activeLink]);

  return { progressBarWidth, progressBarOffset };
};
