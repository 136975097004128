type ComponentProps = {
  title: string;
  specs: string[];
};

export const SpecItem = ({ title, specs }: ComponentProps) => {
  return (
    <>
      <img
        className="specifictaions__item-image"
        src={require(`@/assets/images/sections/bikes/models/${title.toLowerCase()}.jpg`)}
        alt={title}
      />
      <figcaption className="specifictaions__item-caption">{title}</figcaption>
      <ul className="specifictaions__item-list">
        {specs.map((spec) => {
          const [specName, value] = spec.split(":");
          return (
            <li className="specifictaions__item-list__item" key={spec}>
              <span className="specifictaions__item-list__item-name">{specName}</span>
              <span className="specifictaions__item-list__item-value">{value.trim()}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};
