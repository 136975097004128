import story from "@/assets/images/sections/oduwan/story-1.jpg";
import { SectionHeader } from "@/sections/components/section-header";

import "./styles.scss";

export const Story = () => (
  <section className="section story">
    <SectionHeader title="Story" />
    <div className="section__content">
      <p className="section__text">
        I am Illia and I make cycles.
        <span className="br" /> Having accumulated years of metalworking and engineering experience
        from factory floors to motocross tracks, I founded Oduwan as an answer to blandness of mass
        market and pretentiousness of modern custom builders.
        <span className="br" /> I know what a good frame is, and I never settle until I am fully
        content with my work. While others promise to build bikes for you, I build them for me.
        <span className="br" /> This is why Oduwan is better.
      </p>
      <img src={story} alt="" className="section__image" />
    </div>
  </section>
);
