import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { ModalProvider } from "@/components/modal";
import { router } from "@/routes";

import "./index.scss";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    rootElement,
    <ConfigProvider
      theme={{
        components: {
          Notification: {
            colorBgElevated: "#d8d8d8",
          },
        },
      }}
    >
      <ModalProvider>
        <RouterProvider router={router} />
      </ModalProvider>
    </ConfigProvider>,
  );
} else {
  root.render(
    <ConfigProvider
      theme={{
        components: {
          Notification: {
            colorBgElevated: "#d8d8d8",
          },
        },
      }}
    >
      <ModalProvider>
        <RouterProvider router={router} />
      </ModalProvider>
    </ConfigProvider>,
  );
}
